import React, { useState } from "react";
import { Form, Input, Button, Slider, Divider, Radio, Alert, Spin } from "antd";
import { Results } from "./Results";
import axios from 'axios';
import arrow from "./arrow.png"

let annualHammerLow: number;
let annualHammerHigh: number;
let incAnnualHammerLow: number;
let incAnnualHammerHigh: number;
let newCustomersPerYearLow: number;
let newCustomersPerYearHigh: number;
let salesPerYearAvg: number;
let lowIncPerAuction: number;
let highIncPerAuction: number;

const Calculator = (props: any) => {
  const [invalidSubmit, setInvalidSubmit] = useState(true);
  const [emailValid, setEmailValid] = useState<any>();
  const [customers, setCustomers] = useState(5000);
  const [salesPerYear, setSalesPerYear] = useState("");
  const [lotsPerSale, setLotsPerSale] = useState(400);
  const [percentSold, setPercentSold] = useState(70);
  const [resultsPage, setResultsPage] = useState(false);
  const [fieldsEmpty, setFieldsEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [annualHammer, setAnnualHammer] = useState("");

  const customerMarks = {
    0: "0",
    10000: "10K",
    20000: "20K",
    30000: "30K",
    40000: "40K",
    50000: "50K",
  };

  const itemMarks = {
    0: "0",
    500: "500",
    1000: "1000",
    1500: "1500",
    2000: "2000",
  };

  const percentMarks = {
    0: "0%",
    25: "25%",
    50: "50%",
    75: "75%",
    100: "100%",
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  function handleAnnualHammerPrice(value: any) {
    setAnnualHammer(value);
    if (value === "over $50M") {
      annualHammerLow = 51000000;
      annualHammerHigh = 60000000;
    }else{
      annualHammerLow = value.split('$').pop().split("M")[0] * 1000000;
      annualHammerHigh = value.split('-').pop().split("M")[0] * 1000000;
    }
    
  }

  function handleSalesPerYear(value: any) {
    setSalesPerYear(value);
    if (value === "1-10") {
      salesPerYearAvg = 5;
    } else if (value === "11-20") {
      salesPerYearAvg = 15;
    } else if (value === "21-40") {
      salesPerYearAvg = 30;
    } else if (value === "More than 40") {
      salesPerYearAvg = 45;
    }
  }

  function validateEmail(value: string) {
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (String(value).match(regexEmail)) {
      setEmailValid("success");
      setInvalidSubmit(false);
      setEmail(String(value));
    } else {
      setInvalidSubmit(true);
      setEmailValid("error");
    }
  }

  

  function onSubmit() {
    if (salesPerYear === "" || !annualHammerLow) {
      setFieldsEmpty(true);
    } else {
      setIsLoading(true);
      incAnnualHammerLow = annualHammerLow * 0.15;
      incAnnualHammerHigh = (
        lotsPerSale *
        salesPerYearAvg *
          1.1 *
          1.1 *
          (annualHammerLow / (lotsPerSale * salesPerYearAvg)) -
          annualHammerLow
      );
      newCustomersPerYearLow = customers * 0.04;
      newCustomersPerYearHigh = customers * 0.095;
      lowIncPerAuction = incAnnualHammerLow / salesPerYearAvg;
      highIncPerAuction = incAnnualHammerHigh / salesPerYearAvg;

      let response = {
        "response": {
          "email": email,
          "date": new Date().toJSON().slice(0,10).replace(/-/g,'/'),
          "numberOfCustomers": JSON.stringify(customers),
          "auctionsPerYear": salesPerYear,
          "lotsPerSale": JSON.stringify(lotsPerSale),
          "sellThroughRate (%)": JSON.stringify(percentSold),
          "annualHammerPrice": annualHammer,
          "incAnnualHammerLow": incAnnualHammerLow,
          "incAnnualHammerHigh": incAnnualHammerHigh,
          "newCustomersPerYearLow": JSON.stringify(newCustomersPerYearLow),
          "newCustomersPerYearHigh": JSON.stringify(newCustomersPerYearHigh),
          "lowAvgIncPerAuction": formatter.format(lowIncPerAuction),
          "highAvgIncPerAuction": formatter.format(highIncPerAuction)

        }
      }
      axios.post('https://v2-api.sheety.co/9cb74039f2f40f9495753ace0ab3e377/roiCalculatorResponses/responses', response);
      setTimeout(() => setIsLoading(false), 1500);
      setTimeout(() => setResultsPage(true), 1500);
    }
  }
  if (!resultsPage) {
    return (
      <div>
        <div className="calculator">
          <h1>ROI Calculator</h1>
          <p className="subtitle">
          Discover the revenue increase you could see with the use of Artbrain's personalization technology, based on our extensive experience with auction houses around the world.
          </p>
          <div style={{ margin: "0 auto" }}>
            <img
              src={arrow}
              alt='Continue'
            ></img>
          </div>
          <Divider />
          <Form>
            <Form.Item>
              <h2>How many customers do you have?</h2>
              <h5>{customers}</h5>
              <br />
              <Slider
                marks={customerMarks}
                min={0}
                max={50000}
                step={1000}
                onChange={(value: any) => setCustomers(value)}
                value={typeof customers === "number" ? customers : 0}
              />
              <p className="description">
              A customer is someone who is listed as 'subscribed' and that you have their valid email address.
              </p>
            </Form.Item>
            <Divider />
            <Form.Item>
              <h2>How many auctions do you hold per year?</h2>
              <br />
              <Radio.Group onChange={(e) => handleSalesPerYear(e.target.value)}>
                <Radio.Button
                  style={{
                    padding: 0,
                    borderRadius: 7,
                    border: "1px #d9d9d9 solid",
                  }}
                  value="1-10"
                >
                  1 - 10
                </Radio.Button>
                <Radio.Button
                  style={{
                    padding: 0,
                    borderRadius: 7,
                    border: "1px #d9d9d9 solid",
                  }}
                  value="11-20"
                >
                  11 - 20
                </Radio.Button>
                <Radio.Button
                  style={{
                    padding: 0,
                    borderRadius: 7,
                    border: "1px #d9d9d9 solid",
                  }}
                  value="21-40"
                >
                  21 - 40
                </Radio.Button>
                <Radio.Button
                  style={{
                    padding: 0,
                    borderRadius: 7,
                    border: "1px #d9d9d9 solid",
                  }}
                  value="More than 40"
                >
                  More than 40
                </Radio.Button>
              </Radio.Group>
              <p className="description">
                Please consider all auctions, whether specialist or general
                sales.
              </p>
            </Form.Item>
            <Divider />
            <Form.Item>
              <h2>How many lots do you usually offer per auction?</h2>
              <h5>{lotsPerSale}</h5>
              <br />
              <Slider
                marks={itemMarks}
                min={0}
                max={2000}
                step={100}
                onChange={(value: any) => setLotsPerSale(value)}
                value={typeof lotsPerSale === "number" ? lotsPerSale : 0}
              />
            </Form.Item>
            <Divider />
            {/* <Form.Item>
              <h2>What is your average sell-through rate per auction?</h2>
              <h5>{percentSold}%</h5>
              <br />
              <Slider
                marks={percentMarks}
                min={0}
                max={100}
                step={5}
                onChange={(value: any) => setPercentSold(value)}
                value={typeof percentSold === "number" ? percentSold : 0}
              />
              <p className="description">
                Refer to the percentage of items sold successfully in an
                auction, out of the total number of items offered for sale.
              </p>
            </Form.Item>
            <Divider /> */}
            <Form.Item>
              <h2>What was your total hammer price last year?</h2>
              <br />
              <Radio.Group
                onChange={(e) => handleAnnualHammerPrice(e.target.value)}
              >
                <Radio.Button style={{ borderRadius: 7 }} value="$1M-5M">
                  $1M - $5M
                </Radio.Button>
                <Radio.Button
                  style={{ borderRadius: 7, border: "1px #d9d9d9 solid" }}
                  value="$6M-10M"
                >
                  $6M - $10M
                </Radio.Button>
                <Radio.Button
                  style={{ borderRadius: 7, border: "1px #d9d9d9 solid" }}
                  value="$11M-25M"
                >
                  $11M - $25M
                </Radio.Button>
                <Radio.Button
                  style={{ borderRadius: 7, border: "1px #d9d9d9 solid" }}
                  value="$26M-50M"
                >
                  $26M - $50M
                </Radio.Button>
                <Radio.Button
                  style={{ borderRadius: 7, border: "1px #d9d9d9 solid" }}
                  value="over $50M"
                >
                  Over $50M
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Divider />
            <h2>What is your email address?</h2>
            <Form.Item
              required={true}
              validateStatus={emailValid ? emailValid : "undefined"}
              style={{ display: "block" }}
            >
              <Input onChange={(e) => validateEmail(e.target.value)}></Input>
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => onSubmit()}
              disabled={invalidSubmit}
              style={{
                width: 200,
                height: 40,
                color: "white",
                fontWeight: "bold",
                borderRadius: 5,
                fontSize: 15,
                letterSpacing: 0.8,
              }}
            >
              Calculate My ROI
            </Button>
            {fieldsEmpty && (
              <Alert
                message="Please fill out all fields before submitting."
                type="error"
                showIcon
                style={{ marginTop: 20, color: "red" }}
              />
            )}
            <p className="footer-text">
            This estimate is based on average results achieved by auction houses using Artbrain’s solutions, and the information you provided. Actual results may vary.

            </p>
          </Form>
          {isLoading && (
            <div className="overlay">
              <Spin
                size="large"
                tip="Calculating..."
                style={{ position: "relative", top: "30%" }}
              />
            </div>
          )}
        </div>
      </div>
    );
  } else
    return (
      <Results
        email={email}
        incAnnualHammerLow={incAnnualHammerLow}
        incAnnualHammerHigh={incAnnualHammerHigh}
        newCustomersPerYearLow={newCustomersPerYearLow}
        newCustomersPerYearHigh={newCustomersPerYearHigh}
        salesPerYearAvg={salesPerYearAvg}
      ></Results>
    );
};

export default Calculator;
