import React from "react";
import "antd/dist/antd.css";
import "./App.css";
import Calculator from "./Calculator";
import { Divider } from "antd";
import logo from './logo.png';


function App() {
  return (
    <div className="App">
      <div className="header">
        <a href="https://www.artbrain.co/?utm_source=roicalculator&utm_medium=link&utm_campaign=roi_calculator">
          <img
            className="logo"
            src={logo}
          ></img>
        </a>
      </div>
      <Divider style={{ margin: 0 }}></Divider>
      <div className="headerImage"></div>
      <Calculator></Calculator>

      <div className="footer">
        <p className="copyright">
          Copyright © 2020 Artbrain Ltd. All rights reserved.{" "}
        </p>
        <a
          className="footer-link"
          href="https://calendly.com/yair-z/artbrain-introduction?month=2020-08"
          // style={{ float: "right", margin: "0px 20px" }}
        >
          Book a demo
        </a>
        <a
          className="footer-link"
          href="https://www.artbrain.co"
          // style={{ float: "right", margin: "0px 20px" }}
        >
          To Artbrain's website
        </a>
      </div>
    </div>
  );
}

export default App;
