import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { Button, Modal, Divider } from "antd";
// import { DemoForm } from "./DemoForm";
// import { CaseStudy } from "./CaseStudy";
import arrow from "./arrow.png"

type ResultsProps = {
  email: string;
  incAnnualHammerLow: number;
  incAnnualHammerHigh: number;
  newCustomersPerYearLow: number;
  newCustomersPerYearHigh: number;
  salesPerYearAvg:number
};

export const Results = (props: ResultsProps) => {
  // const [modalVisible, setModalVisible] = useState(false);

  // function requestDemo() {
  //   setModalVisible(true);
  // }

  // function handleSubmit() {
  //   setModalVisible(false);
  // }

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="results">
        <h1>Projected Annual ROI</h1>
        <p className="subtitle">
        Here's how Artbrain’s personalization engine can help you re-activate inactive customers, increase average hammer price, and improve sell-through rate.
        </p>
        <div style={{ width: 15, height: 17, margin: "0 auto" }}>
          <img
            src={arrow}
            alt='Continue'
          ></img>
        </div>
        <div className="results-box-lg">
          <h4 style={{color:"white"}}>
            {formatter.format(props.incAnnualHammerLow)} - {formatter.format(props.incAnnualHammerHigh)}
          </h4>
          <h2 style={{color:"white", float: "none", textAlign: "center", display:"block"}} className="results-subtext">
          Estimated annual revenue missed by not using Artbrain's technology
          </h2>
          <p className="results-text" style={{color:"white", marginTop:45}}>Average increase in hammer price per auction:</p>
            <h5 style={{color:"white", float: "none", textAlign: "center", display:"block"}}>{formatter.format(props.incAnnualHammerLow/props.salesPerYearAvg)} - {formatter.format(props.incAnnualHammerHigh/props.salesPerYearAvg)}</h5>
        </div>
        <div style={{ textAlign: "center" }}>
          <div className="container-sm">
            <h2>Increase in sell-through rate:</h2>
            <h5>5% - 10%</h5>
          </div>
          <div className="container-sm" style={{ marginBottom: 0 }}>
            <h2>Re-activated customers per year:</h2>

            <h5>
              {props.newCustomersPerYearLow} - {props.newCustomersPerYearHigh}
            </h5>
          </div>
          <p className="footer-text" style={{marginBottom: 0}}>
          These results are determined based on average results seen with similar auction houses, using Artbrain solutions. Actual results may vary.
          </p>
          <Button
            type="primary"
            target="_blank"
            href="https://calendly.com/yair-z/artbrain-introduction?month=2020-08"
            style={{
              width: 200,
              height: 40,
              color: "white",
              fontWeight: "bold",
              borderRadius: 5,
              fontSize: 15,
              letterSpacing: 0.8,
              margin: "80px 0px",
              paddingTop: 4,
              // position: "relative",
              // top: "30px",
            }}
          >
            Schedule a Demo
          </Button>
          <br />

         

          <div className="video-wrapper">
            <ReactPlayer
              width="100%"
              url="https://share.vidyard.com/watch/5n21H5wY5VvNbc8zx67auF"
            ></ReactPlayer>
          </div>
          {/* <Button
            type="primary"
            onClick={() => requestDemo()}
            style={{
              width: 200,
              height: 40,
              color: "white",
              fontWeight: "bold",
              borderRadius: 5,
              fontSize: 15,
              letterSpacing: 0.8,
              margin: "90px 0px",
              paddingTop: 4,
            }}
          >
            Request a Demo
          </Button>
          <Modal
            onCancel={() => setModalVisible(false)}
            visible={modalVisible}
            footer={null}
          >
            <h1 style={{ margin: 0 }}>Request a Demo</h1>
            <p
              className="description"
              style={{ marginTop: 30, float: "none", textAlign: "center" }}
            >
              Discover how Artbrain can help you grow your business.
            </p>
            <DemoForm email={props.email} handleSubmit={handleSubmit} />
          </Modal> */}
        </div>
      </div>
      {/* <Divider style={{ margin: 0 }}></Divider> */}
      {/* <h1 style={{ marginBottom: 10 }}>Case studies</h1>
      <p className="subtitle">
        Read our case studies to learn how Artbrain helps art and collectibles
        businesses.{" "}
      </p>
      <CaseStudy
        title={"Maximize Campaign's Performance"}
        linkText={"Read Forum Auctions case study"}
        url={"https://hubs.ly/H0sk3M90"}
      />
      <CaseStudy
        title={"Re-engage Inactive Customers"}
        linkText={"Read RR Auctions case study"}
        url={"https://hubs.ly/H0sk7s-0"}
      /> */}
    </div>
  );
};
